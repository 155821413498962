
  import { defineComponent, ref, onMounted } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import {
    IGetProductUnitForViewDto,
    IProductUnitDto,
  } from "@/shared/service-proxies/service-proxies";
  import { ProductUnitFilter } from "@/models";
  import ProductUnitModal from "@/components/modals/forms/ProductUnitModal.vue";
  import ViewProductUnitModal from "@/components/modals/forms/ViewProductUnitModal.vue";

  import AJLoader from "@/components/AjaxLoader.vue";

  import useProducts from "@/composables/useProducts";
  import DeleteItemService from "@/core/services/DeleteItemService";
  import { useStore } from "vuex";

  export default defineComponent({
    name: "Product-unit",
    components: {
      Datatable,
      ProductUnitModal,
      ViewProductUnitModal,
      AJLoader,
    },
    setup() {
      const { productUnits, getProductUnits, deleteProductUnit } =
        useProducts();

      const breadcrumbs = {
        title: "Product Units",
        breadcrumbs: ["Product Units"],
      };

      const checkedCustomers = ref([]);

      let loading = ref<boolean>(true);
      const showAdvanceFilter = ref<boolean>(false);

      const store = useStore();
      const config = store.getters.getConfigurations;
      const permissions = config.result.auth.grantedPermissions;

      let isEditMode = ref<boolean>(false);

      const defaultModel = ref({
        productUnitName: "",
        productUnitDescription: "",
        conversionFactor: "",
        id: "",
      }) as unknown as IProductUnitDto;

      let modelValue = ref<IProductUnitDto>(defaultModel);

      const defaultFilter = ref({
        skipCount: 0,
        maxResultCount: 20,
      } as unknown as ProductUnitFilter);

      const tableHeader = ref([
        {
          name: "Actions",
          key: "actions",
        },
        {
          name: "Product unit name",
          key: "productUnitName",
          sortable: true,
        },
        {
          name: "Product unit description",
          key: "productUnitDescription",
          sortable: true,
        },
        {
          name: "Conversion Factor",
          key: "conversionFactor",
          sortable: true,
        },
      ]);

      const tableData = ref<Array<IGetProductUnitForViewDto>>([]);

      const initialData = ref<Array<IGetProductUnitForViewDto>>([]);

      onMounted(async () => {
        setCurrentPageBreadcrumbs("Product Units", [
          "Product unit header info",
        ]);

        await getData();
        loading.value = false;
      });

      const setModal = (id: string) => {
        isEditMode.value = true;

        const value = productUnits.value.find((el) => el.productUnit.id == id);

        console.log(value);
        if (value) {
          modelValue.value = value.productUnit;
        }
      };

      const deleteHandler = async (id: string) => {
        const res = await DeleteItemService.delete(
          id as string,
          deleteProductUnit
        );

        if (res) {
          for (let i = 0; i < tableData.value.length; i++) {
            if (tableData.value[i].productUnit.id === id) {
              tableData.value.splice(i, 1);
            }
          }
        }
      };

      const resetModel = () => {
        modelValue.value = { ...defaultModel };
      };

      const getData = async () => {
        loading.value = true;

        await getProductUnits(
          defaultFilter.value as unknown as ProductUnitFilter
        );

        tableData.value = productUnits.value;

        initialData.value.splice(0, tableData.value.length, ...tableData.value);

        loading.value = false;
      };

      const searchFunc = async () => {
        await getData();
      };

      return {
        defaultFilter,
        loading,
        showAdvanceFilter,
        tableData,
        tableHeader,
        checkedCustomers,
        isEditMode,
        modelValue,
        setModal,
        resetModel,
        deleteHandler,
        searchFunc,
        breadcrumbs,
        permissions,
      };
    },
  });
