
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import {
  CreateOrEditProductUnitDto,
  IProductUnitDto,
} from "@/shared/service-proxies/service-proxies";
import useProducts from "@/composables/useProducts";

export default defineComponent({
  name: "product-unit-modal",
  props: {
    modelValue: {
      required: false,
      type: () => Object as unknown as IProductUnitDto,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const territoryModalRef = ref<null | HTMLElement>(null);
    const { updateOrCreateProductUnit } = useProducts();
    const loading = ref<boolean>(false);

    const defaultFormData = ref({
      productUnitName: "",
      productUnitDescription: "",
      conversionFactor: "",
      id: "",
    }) as unknown as CreateOrEditProductUnitDto;

    let formData = ref<CreateOrEditProductUnitDto>(defaultFormData);

    const rules = ref({
      productUnitName: [
        {
          required: true,
          message: "Product unit name is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;

          const response = await updateOrCreateProductUnit(
            formData.value,
            undefined
          );

          if (response) {
            loading.value = false;

            Swal.fire({
              text: "Saved successfully!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Okay!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(territoryModalRef.value);
            });
          } else {
            Swal.fire({
              text: "An error occured with your request.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    watch(
      () => props.modelValue,
      () => {
        formData.value = {
          ...props.modelValue,
        } as unknown as CreateOrEditProductUnitDto;
      }
    );

    return {
      formData,
      rules,
      formRef,
      loading,
      territoryModalRef,
      submit,
    };
  },
});
